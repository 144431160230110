.Container_cz7me90{background:#ffffff;padding:16px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;gap:16px;border-bottom-left-radius:12px;border-bottom-right-radius:12px;}
.Heading_h16hfeh{font-size:0.875rem;color:#858996;}
.DetailsContainer_duo0fap{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;gap:16px;}
.DetailsSection_d8utirm{-webkit-flex:1;-ms-flex:1;flex:1;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;gap:16px;border-left:1px solid #d2d3d8;padding-left:16px;overflow-y:scroll;height:var(--d8utirm-0);}.DetailsSection_d8utirm:first-child{padding:0;border-left:0;}

.DetailsItemHeading_due71g1{font-size:0.875rem;color:#858996;}

.DetailsItemValue_d1tnidjw{font-size:0.875rem;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;gap:8px;}.DetailsItemValue_d1tnidjw img{width:24px;height:16px;margin:0;border-radius:2px;}.DetailsItemValue_d1tnidjw img:hover{width:24px;height:16px;margin:0;}
.DetailsItemTagValue_dc12z52{font-size:0.75rem;background:#244F6B;padding:0 4px;border-radius:4px;color:#ffffff;display:-webkit-inline-box;display:-webkit-inline-flex;display:-ms-inline-flexbox;display:inline-flex;-webkit-letter-spacing:1px;-moz-letter-spacing:1px;-ms-letter-spacing:1px;letter-spacing:1px;}
.DetailsItemFlagValue_dezmnnx.DetailsItemValue_d1tnidjw{padding-top:3px;}
.DetailsItemLargeValue_d89eu91.DetailsItemValue_d1tnidjw{margin-bottom:8px;}
.ToggleItemsButton_tj70422{all:unset;font-size:0.75rem;color:#858996;cursor:pointer;}.ToggleItemsButton_tj70422:focus{outline:none;}
