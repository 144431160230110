.OrganisationOverviewSection_o1u476py{color:#474952;margin-bottom:20px;}
.OverviewContainer_ob5yfjf{display:grid;grid-template-areas:var(--ob5yfjf-0);grid-template-columns:1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;}
.OverviewCell_o16yqfvi{background-color:#ffffff;border:1px solid #EDEDED;padding:16px;}
.OrgLogo_o1bt5zyy.OverviewCell_o16yqfvi{grid-area:org-logo;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;border-top:none;padding-top:10px;}
.OrgDetails1Cell_otqv5ru.OverviewCell_o16yqfvi{grid-area:org-details-1;}
.OrgDetails2Cell_o1ufy40j.OverviewCell_o16yqfvi{grid-area:org-details-2;overflow-y:auto;}
.OrgPresence_o19m2a1r.OverviewCell_o16yqfvi{grid-area:org-scale;position:relative;}
.OrgLogoContainer_o4maoew{object-fit:contain;height:88px;}
.RiskIcons_r15pqzyi.OverviewCell_o16yqfvi{grid-area:risk-icons;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;border-top:none;padding-bottom:4px;padding-top:4px;}
.ScreeningRiskIcons_s1y8zdo4.OverviewCell_o16yqfvi{grid-area:screening-risk-icons;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;border-top:none;padding-bottom:4px;padding-top:4px;}
.OrgScaleItem_o1tu2m0h{-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;margin-bottom:9px;}
.ScreeningRiskHeader_sh6jqz2.OverviewCell_o16yqfvi{grid-area:screening-risk-icons-title;padding:0;padding-top:12px;padding-left:16px;border:1px solid #EDEDED;border-top:none;border-bottom:none;}
.RiskHeader_r1uo3y06.OverviewCell_o16yqfvi{grid-area:risk-icons-title;padding:0;padding-top:12px;padding-left:16px;border:1px solid #EDEDED;border-top:none;border-bottom:none;}
.OrgLogoHeader_o1eig7j5.OverviewCell_o16yqfvi{grid-area:org-logo-title;padding:0;padding-top:12px;padding-left:16px;border:1px solid #EDEDED;border-top:none;border-bottom:none;}
.EmployeeCardIcon_ervhgol{width:30px;height:30px;}.EmployeeCardIcon_ervhgol path{fill:var(--ervhgol-0);}
.RelatedOrgsIcon_r1tge0cw{width:30px;height:30px;}.RelatedOrgsIcon_r1tge0cw path{fill:var(--r1tge0cw-0);}
.GlobeIcon_g4c8fzm{width:30px;height:30px;}.GlobeIcon_g4c8fzm path{fill:var(--g4c8fzm-0);}
.SocialReachIcon_s1jxviqy{width:30px;height:30px;}.SocialReachIcon_s1jxviqy path{fill:var(--s1jxviqy-0);}
.StateOwnedFlagIcon_s13mn5zp{width:64px;height:64px;padding-bottom:7px;}.StateOwnedFlagIcon_s13mn5zp path{fill:var(--s13mn5zp-0);}
.MajorCrimesFlagIcon_me8s5p6{width:64px;height:64px;padding-bottom:7px;}.MajorCrimesFlagIcon_me8s5p6 path{fill:var(--me8s5p6-0);}
.FinCrimeRiskFlagIcon_fmi0p4e{width:64px;height:64px;padding-bottom:7px;}.FinCrimeRiskFlagIcon_fmi0p4e path{fill:var(--fmi0p4e-0);}
.ESGIcon_e5jei7b{width:64px;height:64px;padding-bottom:7px;}.ESGIcon_e5jei7b path{fill:var(--e5jei7b-0);}
.RiskFlagIcon_r1wzdaoi{width:64px;height:64px;padding-bottom:7px;}.RiskFlagIcon_r1wzdaoi path{fill:var(--r1wzdaoi-0);}
.GraphicSubtext_g6juu74{font-size:0.875rem;color:#858996;margin:0;margin-top:2px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;}
.OrgScaleDetail_o1ns6k2s{font-size:0.875rem;color:var(--o1ns6k2s-0);}.OrgScaleDetail_o1ns6k2s span{font-family:Inter-SemiBold,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;font-weight:600;padding:0 4px;}
.OrgProfiles_o1tfjufp.OverviewCell_o16yqfvi{grid-area:org-profiles;overflow-y:auto;padding-right:11px;}
.OrgSummary_o49cvzk.OverviewCell_o16yqfvi{grid-area:org-summary;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;position:relative;}.OrgSummary_o49cvzk.OverviewCell_o16yqfvi p{font-size:0.875rem;margin:0;}
.OrgSummaryContainer_or31iis{max-height:170px;overflow-y:auto;}
.LinkToSummary_lwf7e2s{color:#858996 !important;font-size:0.875rem !important;min-width:0;border-bottom:1px solid transparent;}.LinkToSummary_lwf7e2s:hover,.LinkToSummary_lwf7e2s:focus{border-color:#858996;}
.OrgDescription_o7ksix2.OverviewCell_o16yqfvi{grid-area:org-description;}.OrgDescription_o7ksix2.OverviewCell_o16yqfvi p{font-size:0.875rem;margin-bottom:0;}
.OrgDescriptionContainer_o19u8ej{font-size:0.875rem;margin-bottom:0;height:170px;overflow-y:auto;}
.QuotesIcon_qp3cn5w{float:left;margin:5px 10px 0 0;}.QuotesIcon_qp3cn5w path{fill:var(--qp3cn5w-0);}
.OrgDescriptionText_o1rb59q7:hover{cursor:var(--o1rb59q7-0);}
.SectionTitle_sx3vsdl{font-family:Inter-SemiBold,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;font-size:0.875rem;color:#858996;margin:0;}
.OrgScaleTitle_oxbmxek.SectionTitle_sx3vsdl{margin-bottom:4px;}
.OnlineProfilesTitle_ohr6paz.SectionTitle_sx3vsdl{margin-bottom:4px;}
.OrgDescriptionTitleContainer_o7qesf0{display:-webkit-inline-box;display:-webkit-inline-flex;display:-ms-inline-flexbox;display:inline-flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}
.OrgDescriptionTitle_o1gipmlk{color:#858996;font-size:0.875rem;margin:0;max-width:220px;display:inline;}
.ShowAllButton_s12y0ft{color:#858996 !important;font-size:0.75rem !important;min-width:0;padding-top:8px !important;}.ShowAllButton_s12y0ft:hover,.ShowAllButton_s12y0ft:focus{border-color:#858996 !important;}
.OrgDuration_oyh0bwj{color:#858996;}
.HQContainer_htmz6gq{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;}
.JurisdictionContainer_j1wtnq58{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;}
.CustomFlag_cpwthsx{width:31px;}.CustomFlag_cpwthsx img{width:24px !important;height:16px !important;margin:0;margin-top:1px !important;margin-right:5px !important;border-radius:2px !important;}
.InformationIcon_iutjv99{height:20px;width:20px;padding-left:2px;}
.PopoverHomepageImage_pbi38yi{padding-right:6px;}
.ImageLoadingSpinner_i147mjz{color:#19a49b;position:absolute;}
.HomepageLink_hibqhg9{color:#858996 !important;font-size:0.875rem;}.HomepageLink_hibqhg9:hover{color:#007AFF !important;}
.OnlineProfilesContainer_o2db7ww{max-height:140px;overflow-y:auto;padding-right:5px;}
.TooltipContent_t1af320o{text-align:left;padding:2px;max-height:325px;overflow-y:auto;width:240px;}
.DescriptionSentenceSeparator_degvk63{white-space:pre;margin:0 2px;}
.DescriptorSentenceContainer_d10x1wcb{display:inline;}.DescriptorSentenceContainer_d10x1wcb:last-child .DescriptionSentenceSeparator_degvk63{margin:0;}
.DescriptorSources_d1w6hptm{font-size:0.875rem;color:#858996;}.DescriptorSources_d1w6hptm a{color:#858996;}.DescriptorSources_d1w6hptm a:hover{color:#474952;}
.DescriptionSourceContainer_dpdghqk{max-width:400px;text-overflow:ellipsis;overflow:hidden;white-space:nowrap;}
.NoDate_n14niecj{color:#858996;}
.RoleDuration_r1y5x7bx{color:#858996;}
.Info_ij5bu7j{font-size:0.875rem;color:#858996;white-space:nowrap;}
.Founders_f3vt36f:hover{cursor:pointer;}
.WebsitePlaceholder_w16u9r41{max-width:224px;max-height:120px;border-radius:3px;}
.SocialReachTooltipList_s10z10bz{padding-top:8px;padding-left:25px;margin:0;}
.NoSocialPresenceLabel_nqqb5w1{margin:0;padding-bottom:8px;}
.SummaryText_spxf6a1{word-break:break-word;}.SummaryText_spxf6a1:hover{cursor:var(--spxf6a1-0);}
.PlaceholderText_ptyktew{font-style:italic;color:#b3b5bd;}
.SummaryTooltipContent_spklk18.TooltipContent_t1af320o{width:400px;}
.InfographicContainer_i8fosbg{position:relative;margin:auto;width:-webkit-fit-content;width:-moz-fit-content;width:fit-content;}
.OrgScaleIconContainer_o5z62rt{position:relative;}
.RiskCountIcon_rrrd6jb{position:absolute;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;aspect-ratio:1;min-width:21px;bottom:-6px;right:-3px;border-radius:50%;font-size:0.625rem;background-color:#ef7273;line-height:17px;color:#ffffff;border:2px solid #EDEDED;padding:var(--rrrd6jb-0);}.RiskCountIcon_rrrd6jb:hover{cursor:pointer;}
.RiskTooltipBody_r1ho4ayu{max-height:200px;overflow-y:auto;padding-top:5px;}
.RiskList_rp8td4e{margin-bottom:8px;line-height:1.3;font-size:0.875rem;}
.Risks_rdezcem{color:#858996;}
.RiskAssignerText_r10a72es{color:#858996;}
.StreetviewImage_s9nu1xg{border-radius:3px;}
.Tooltip_t15rvx36 + div{display:inline;}
.FounderPill_f79su38{text-align:left;}
.Duration_dm3egcz{color:var(--dm3egcz-0) !important;font-size:0.875rem;}.Duration_dm3egcz .NoDate_n14niecj{color:var(--dm3egcz-1);}
.FounderContainer_f12a0xp6{padding-bottom:6px;}
.SmallEmployeeIconInfographic_s1xnq9uk{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}.SmallEmployeeIconInfographic_s1xnq9uk .EmployeeCardIcon_ervhgol{height:35px;width:35px;padding-bottom:0;}
.SmallSocialReachIconInfographic_sgm7w6{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}.SmallSocialReachIconInfographic_sgm7w6 .SocialReachIcon_s1jxviqy{height:35px;width:35px;padding-bottom:0;}
.HomepageProfile_hnty560{padding:2px 4px;}
.BuildingIconContainer_b69ip5w{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;background-color:#dfe1e3;height:64px;width:64px;border-radius:3px;}
.BuildingIcon_b1pqg9m1 path{fill:#ffffff;}
.MediaIconsContainer_m1dke4o7{padding:var(--m1dke4o7-0);}
.SocialReachInfoIcon_siiawbw{margin-bottom:3px;}
.BulletPoint_b1goveut{height:4px;width:4px;display:inline-block;border-radius:50%;margin-right:8px;background-color:#474952;margin-top:3px;margin-bottom:3px;}
.SourceContainer_sr0oup2{max-height:275px;overflow:auto;}
.CountryFlag_chazzw9{width:30px;}.CountryFlag_chazzw9 img{width:24px !important;height:18px !important;margin:0;margin-right:8px !important;border-radius:2px !important;}
.Country_c5c6oaw{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;}
.InspectorContent_ilyqa4j{max-height:175px;overflow:auto;margin-right:-5px;padding-right:5px;}
.StateOwnedCompany_s124idud:not(:first-of-type){border-top:1px solid #d2d3d8;margin-top:11px;padding-top:2px;}
.RiskCountry_rojaaro:not(:first-of-type){border-top:1px solid #d2d3d8;margin-top:8px;padding-top:8px;}
.CountryName_ci9j864{font-size:0.875rem;padding-bottom:4px;}
.CompaniesList_c1pa84as{padding-left:var(--c1pa84as-0);}
.CompanyField_chp1lhc{padding-left:11px;text-indent:-12px;}
.RelatedCountryHeader_r1gqr4p0{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}
.RiskAssigners_r2dxos4{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;padding-top:8px;gap:8px;}
.CustomSectionFooter_c11zzkvo{border:1px solid #EDEDED !important;}
.ExplainerText_e4shtce{font-size:0.875rem;}
.OrgIconContainer_onmgdss{width:36px;height:36px;border-radius:3px;background-color:#19a49b;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;margin-right:8px;}
.OrgIcon_ot17ngc{-webkit-transform:scale(0.65);-ms-transform:scale(0.65);transform:scale(0.65);}
.InfoSummaryRow_i1v4l4uv:not(:last-of-type){margin-bottom:8px;}.InfoSummaryRow_i1v4l4uv ul{padding:0 14px;margin:0;}
.SectionLinkButton_sg9o1m0{border-bottom:1px solid #858996 !important;color:#858996 !important;font-size:0.875rem !important;line-height:13px;}
.List_l1fzqe8n{margin-top:11px !important;padding-top:14px !important;padding-left:0 !important;border-top:1px solid #d2d3d8;list-style:none;}
.ListItem_l150famd{font-size:0.875rem;}.ListItem_l150famd + .ListItem_l150famd{margin-top:12px;}
.CompanyLocation_cigod05{color:#858996;}
.RiskIconsSectionTitle_rkah6tm.SectionTitle_sx3vsdl{margin-bottom:4px;}
.OrgLogoSectionTitle_ot6e5uo.SectionTitle_sx3vsdl{margin-bottom:4px;}
.NoneIdentified_n9qjy15{color:#b3b5bd;font-size:0.875rem;font-style:italic;}
.StateOwnedCompanies_s1y5th09{overflow:auto;height:100%;}
.CustomSourceList_c5lzj6g{overflow:inherit;}
.IndirectExplainer_ily0jwe{padding-top:8px;}
.dataField_d18th7bo dt{color:#474952;margin:0;}.dataField_d18th7bo dd{margin-bottom:8px;}
.orgLogoImage_o1hy9g7h{border-radius:3px;height:88px;width:94px;object-fit:contain;}
.locationRiskTag_lddpvk1{border-radius:11px;height:23px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;padding-left:8px;opacity:1 !important;background-color:#ef7273 !important;}.locationRiskTag_lddpvk1 > svg{margin-top:0px;margin-right:-3px;}
