.ErrorIcon_eo1ng74{color:#f45b5b !important;}
.LoadingIcon_l73ayxz{width:16px;height:16px;}
.ContentContainer_cxvcdks{border-bottom-right-radius:10px;background:#ffffff;color:#000000;-webkit-flex:1;-ms-flex:1;flex:1;position:relative;}
.ContentSection_c2f2y1c{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-transform:translate3d(0,0,0);height:var(--c2f2y1c-0);overflow-y:auto;max-height:none;}.ContentSection_c2f2y1c > div:first-child{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;}.ContentSection_c2f2y1c [class*="DJDisclaimer"]{-webkit-flex:0;-ms-flex:0;flex:0;display:grid;}
.ContentNodeContainer_cr6g000{padding:24px 24px 24px 44px;}
.InsightsChatContainer_i457kjq{padding:0 24px 0 44px;}
.AskXapienButton_a1mt8t7q{margin-bottom:25px;border:0;border-radius:100px;padding:8px 16px 8px 10px;gap:0.3rem;color:#000000;font-size:0.875rem;font-family:InterDisplay-SemiBold,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;-webkit-transition:background-color 0.3s;transition:background-color 0.3s;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;cursor:pointer;border:none;background:var(--a1mt8t7q-0);}.AskXapienButton_a1mt8t7q:hover{background:var(--a1mt8t7q-1);}.AskXapienButton_a1mt8t7q:focus{outline:none;}.AskXapienButton_a1mt8t7q span{color:#7439B3;text-transform:capitalize;}
.AskXapienButtonIcon_a1e7a6fb rect{fill:#7439B3;}
.Divider_d1so48g8{margin:50px 0 25px;}
.EducationalModalWrapper_e5yrtuw{position:absolute;top:0;right:0;}
