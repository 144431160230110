.TooltipContent_t19zt6dz{text-align:left;overflow-y:auto;font-size:0.875rem;}
.TooltipHeader_tcdzdai{border-bottom:1px solid #d2d3d8;padding-bottom:6px;}
.TooltipBody_t1xay4q6{padding:8px 0;}
.SearchDetails_s117ulce{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;}
.TranslationButton_t1uofbrw{cursor:pointer;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;background:#ffffff;padding:6px;border-radius:3px;border:0;}.TranslationButton_t1uofbrw:focus{outline:none;}
.TranslationSwitchButton_t97h6r9{cursor:pointer;color:#1F7DD9;background:transparent;border:0;padding:0;}.TranslationSwitchButton_t97h6r9:focus{outline:none;}

.TranslationPopover_t1oijvha{border-radius:12px;background:#ffffff;padding:16px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;gap:6px;-webkit-align-items:flex-start;-webkit-box-align:flex-start;-ms-flex-align:flex-start;align-items:flex-start;position:relative;}

.ReportTitle_r4d844n{color:var(--r4d844n-0);}
.InfoIcon_iqywf1p{margin-left:2px;display:inline;}.InfoIcon_iqywf1p > div{display:inline;}
.OldReportPrompt_o1lroewv{background-color:var(--o1lroewv-0);width:100%;min-height:92px;border-radius:12px;padding:16px;margin:10px 0;font-size:0.875rem;color:#474952;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;gap:20px;}
.PromptText_pq1g9y3{max-width:790px;}
.RegenIcon_rg5jb32{height:48px;min-width:42px;margin-left:4px;}.RegenIcon_rg5jb32 path{fill:var(--rg5jb32-0);}
.Link_l1kphksk{color:var(--l1kphksk-0) !important;-webkit-text-decoration:underline !important;text-decoration:underline !important;}
.ReportDetailsContainer_r1pc3sgn{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}
.ReportDetails_rtqrvbw{color:#858996;font-size:0.875rem;padding-left:13px;}.ReportDetails_rtqrvbw span + span{border-left:1px solid #d2d3d8;padding-left:0.625rem;margin-left:0.625rem;}
.AccessButton_av0ow1u{background-color:#19a49b;color:#ffffff;}
.dataField_d54s5bg dt{margin:0;}.dataField_d54s5bg dd{margin-bottom:8px;}
.infoIconTooltipContent_iq5eedr{width:280px !important;}
