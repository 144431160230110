.Heading1_h1l8c0d1{display:inline-block;font-family:InterDisplay-SemiBold,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;font-size:1.5rem;font-weight:inherit;-webkit-letter-spacing:-0.69px;-moz-letter-spacing:-0.69px;-ms-letter-spacing:-0.69px;letter-spacing:-0.69px;line-height:1.33333333;margin:0;margin-bottom:10px;border-bottom:2px solid transparent;cursor:pointer;}.Heading1_h1l8c0d1:focus{outline:none;}.Heading1_h1l8c0d1[contenteditable="true"]{cursor:text;color:var(--h1l8c0d1-0);border-bottom:2px solid var(--h1l8c0d1-1);}.Heading1_h1l8c0d1 .InspectorInnerContainer_i13b6beu{color:unset;}
.Heading2_h1ovlyl9{display:inline-block;font-family:InterDisplay-SemiBold,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;font-size:1.125rem;font-weight:inherit;-webkit-letter-spacing:-0.48px;-moz-letter-spacing:-0.48px;-ms-letter-spacing:-0.48px;letter-spacing:-0.48px;line-height:1.44444444;margin-bottom:8px;cursor:pointer;border-bottom:2px solid transparent;}.Heading2_h1ovlyl9:focus{outline:none;}.Heading2_h1ovlyl9[contenteditable="true"]{cursor:text;color:var(--h1ovlyl9-0);border-bottom:2px solid var(--h1ovlyl9-1);}.Heading2_h1ovlyl9 .InspectorInnerContainer_i13b6beu{color:unset;}
.Heading3_h1vdygr7{display:inline-block;font-family:InterDisplay-SemiBold,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;font-size:1rem;font-weight:inherit;-webkit-letter-spacing:-0.57px;-moz-letter-spacing:-0.57px;-ms-letter-spacing:-0.57px;letter-spacing:-0.57px;line-height:1.5;margin-bottom:4px;color:#1F7DD9;cursor:pointer;border-bottom:2px solid transparent;}.Heading3_h1vdygr7:focus{outline:none;}.Heading3_h1vdygr7[contenteditable="true"]{cursor:text;color:var(--h1vdygr7-0);border-bottom:2px solid var(--h1vdygr7-1);}.Heading3_h1vdygr7 .InspectorInnerContainer_i13b6beu{color:unset;}
.Heading4_h1c3zv7e{font-family:InterDisplay-SemiBold,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;font-size:0.875rem;font-weight:inherit;-webkit-letter-spacing:-0.57px;-moz-letter-spacing:-0.57px;-ms-letter-spacing:-0.57px;letter-spacing:-0.57px;margin-bottom:0.5rem;}.Heading4_h1c3zv7e .InspectorInnerContainer_i13b6beu{color:unset;}
.Heading5_h1h47fyc{font-family:Inter-Light,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;font-size:0.625rem;font-weight:inherit;text-transform:uppercase;color:#474952;-webkit-letter-spacing:-0.57px;-moz-letter-spacing:-0.57px;-ms-letter-spacing:-0.57px;letter-spacing:-0.57px;margin-bottom:0.75rem;margin-top:-0.25rem;}.Heading5_h1h47fyc .InspectorInnerContainer_i13b6beu{color:unset;}
.Paragraph_p1ak8bxk{font-size:0.875rem;margin:0;padding-right:25px;padding-bottom:20px;white-space-collapse:preserve-breaks;}.Paragraph_p1ak8bxk .InspectorInnerContainer_i13b6beu{color:unset;}
.Division_dt7nmzw{font-size:0.875rem;margin:0;}
.TickCircle_tqhb0op{padding-right:4px;height:38px;width:38px;}
.ThreeBalls_tjk571m{max-height:38px;max-width:38px;}
.ThreeBallsContainer_tztsvly{position:absolute;top:0;left:-44px;}
.SourcesFetchedSuccessIcon_s1h29re8{color:#19a49b;height:16px;width:16px;}
.SourcesFetchedSuccessIconContainer_sjr93e6{position:absolute;top:2px;left:-30px;}
.SourcesFetchedErrorIcon_s1dzrvgg{color:#e51010;}
.SourcesFetchedErrorIconContainer_saj8gzc{position:absolute;top:2px;left:-39px;}
.ParagraphContainer_pt04s4g{position:relative;}
.Placeholder_p1yv31gu{position:relative;min-width:160px;height:34px;}.Placeholder_p1yv31gu:before{content:"Add section title";display:var(--p1yv31gu-0);color:var(--p1yv31gu-1);position:absolute;top:0;left:0;}
