.CustomModalContainer_c1lzqiki .modal-content{padding:3rem;}
.XapienLogo_x8nul4t{width:48px;height:48px;}.XapienLogo_x8nul4t path{fill:#28effa;}
.Container_c9qkox0{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;gap:1.5rem;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}
.Link_l1hdn3vh{color:#28effa;-webkit-text-decoration:none;text-decoration:none;cursor:pointer;}
.Inputs_ittlpoy{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;gap:1rem;width:100%;}.Inputs_ittlpoy input{width:100%;}
.Title_t17h5php{color:#28effa;}
.Subtitle_s1qskey1{color:#ffffff;}
.Description_d1ux82jh{margin:0;text-align:center;color:#ffffff;}
.Contact_crbbhnq{margin:0;text-align:center;color:#b3b5bd;}.Contact_crbbhnq a{color:#28e4fa;}
.BackButton_bcm3jxd{all:unset;position:absolute;top:1rem;right:1rem;cursor:pointer;}.BackButton_bcm3jxd:not(:focus-visible){outline:none;}
.Main_mir1s3c{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;gap:1rem;}
.QRCodeContainer_qb61wnk{background:white;padding:1rem;border-radius:1rem;}
