.RiskIcons_r1lbzeo2{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;padding:1rem 0;}.RiskIcons_r1lbzeo2 > div{margin:-4px;}
.RiskIcon_r1eq0r2u svg path{fill:var(--r1eq0r2u-0);}.RiskIcon_r1eq0r2u.loading path{-webkit-animation:loadingIcon-RiskIcon_r1eq0r2u 1.5s ease-in-out 0.5s infinite;animation:loadingIcon-RiskIcon_r1eq0r2u 1.5s ease-in-out 0.5s infinite;}@-webkit-keyframes loadingIcon-RiskIcon_r1eq0r2u{0%{opacity:1;}50%{opacity:0.4;}100%{opacity:1;}}@keyframes loadingIcon-RiskIcon_r1eq0r2u{0%{opacity:1;}50%{opacity:0.4;}100%{opacity:1;}}
.TooltipContent_tb8zzpa{margin:0;padding:0.875rem;font-size:0.875rem;max-width:23.125rem;}
.OtherIcon_o9fi87p{width:2rem;height:2rem;background:#ffffff;border-radius:50%;}
.SanctionsIcon_swzxmma{width:2rem;height:2rem;background:#ffffff;border-radius:50%;}
.WatchlistsIcon_w1uf70k2{width:2rem;height:2rem;background:#ffffff;border-radius:50%;}
.PepsIcon_p4qvxvz{width:2rem;height:2rem;background:#ffffff;border-radius:50%;}
.FinancialCrimesIcon_f1xyhv5b{width:2rem;height:2rem;background:#ffffff;border-radius:50%;}
.ProceedingsIcon_p6mzrl3{width:2rem;height:2rem;background:#ffffff;border-radius:50%;}
.Tooltip_t1ok0e05{padding:16px;}
