.Main_m1urdbze{width:100%;height:100vh;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;padding-top:50px;color:#474952;font-size:0.875rem;}
.Viewer_v1bqjb45{background:#ffffff;width:1024px;border-radius:12px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}
.Content_cbzpr92{width:640px;padding:20px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;gap:10px;}
.LoadingContainer_lz0swxn{width:640px;padding:20px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;gap:10px;}.LoadingContainer_lz0swxn .MuiSkeleton-text{-webkit-transform:none;-ms-transform:none;transform:none;}
.LoadingSection_l13xjxhv{padding:10px 0;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;gap:10px;}
.Header_hxy7gys{width:100%;background:#19a49b;border-radius:12px 12px 0 0;padding:10px 20px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;gap:10px;font-size:1.125rem;}
.HeaderTitle_h5nrh7r{color:#ffffff;}
.HeaderSubtitle_h134gdov{color:#ffffff;opacity:0.7;}
.CopyrightContainer_c1c47ei6{border-top:1px solid #d2d3d8;border-bottom:1px solid #d2d3d8;padding:12px 0;font-size:0.75rem;color:#858996;}
.BodyContainer_b1sghkpl{font-family:Inter-Light,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;}.BodyContainer_b1sghkpl a,.BodyContainer_b1sghkpl a:visited{color:#19a49b;-webkit-text-decoration:none;text-decoration:none;}.BodyContainer_b1sghkpl a:hover,.BodyContainer_b1sghkpl a:visited:hover{-webkit-text-decoration:underline;text-decoration:underline;}
.Paragraph_p8ff7oe{margin-bottom:1rem;}
.InfoContainer_in28j6v{border-top:1px solid #d2d3d8;border-bottom:1px solid #d2d3d8;padding:12px 0;}
.ProviderAndDate_p7s51jv{color:#858996;}
.Author_a1s6dk1y{font-family:Inter-SemiBold,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;}
.TimeToRead_t1j26ea6{font-size:0.75rem;color:#858996;}
.Heading_heugo3n{line-height:1.16666667;}
.sourceLinkPopover_s13uqpf9{display:inline;}
