//layout
.flex-center {
  display: flex;
  align-items: center;
}
.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  max-height: 100%;
}
.small-center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-height: 90px;
}
