.PersonalDetailsContainer_p1biiyfq{display:grid;grid-template-areas:var(--p1biiyfq-0);grid-template-columns:repeat(8,1fr);grid-template-rows:auto;}
.GridCell_g1hyqqse{padding:16px;background-color:#ffffff;border:1px solid #EDEDED;}
.ScreeningRiskHeader_s2zfhzv.GridCell_g1hyqqse{grid-area:screening-risk-icons-title;padding:0;padding-top:12px;padding-left:16px;border:none;border-right:1px solid #ffffff;}
.RiskHeader_r15p2cpq.GridCell_g1hyqqse{grid-area:risk-icons-title;padding:0;padding-top:12px;padding-left:16px;border:none;border-left:1px solid #ffffff;}
.RiskIcons_r1sw37w4.GridCell_g1hyqqse{grid-area:risk-icons;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;border-top:none;padding-bottom:4px;padding-top:4px;border-right:none;}
.ScreeningRiskIcons_senz27r.GridCell_g1hyqqse{grid-area:screening-risk-icons;border-left:none;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;border-top:none;padding-bottom:4px;padding-top:4px;}
.PersonImage_p198u8t5.GridCell_g1hyqqse{position:relative;grid-area:person-image;border-top:none;padding:8px;border-right:none;border-left:var(--p198u8t5-0);height:var(--p198u8t5-1);width:var(--p198u8t5-2);}
.Details1_du2lapf.GridCell_g1hyqqse{grid-area:details-1;}
.Details2_dlerjqz.GridCell_g1hyqqse{grid-area:details-2;}
.OnlinePresence_o1xd3tum.GridCell_g1hyqqse{grid-area:online-presence;min-height:280px;}
.Reach_r1egwczm.GridCell_g1hyqqse{grid-area:reach;}
.PersonDescription_p3cr6a0.GridCell_g1hyqqse{grid-area:describe-by-others;}
.FinCrimeRiskFlagIcon_f1lij0ue{width:64px;height:64px;padding-bottom:7px;}.FinCrimeRiskFlagIcon_f1lij0ue path{fill:var(--f1lij0ue-0);}
.MajorCrimesIcon_m1xjd3m5{width:64px;height:64px;padding-bottom:7px;}.MajorCrimesIcon_m1xjd3m5 path{fill:var(--m1xjd3m5-0);}
.ESGIcon_e1rjq1o5{width:64px;height:64px;padding-bottom:7px;}.ESGIcon_e1rjq1o5 path{fill:var(--e1rjq1o5-0);}
.RiskFlagIcon_r1yx48cl{width:64px;height:64px;padding-bottom:7px;}.RiskFlagIcon_r1yx48cl path{fill:var(--r1yx48cl-0);}
.ReachTitle_r3m7hc2{color:#858996;font-size:0.875rem;padding-bottom:4px;}
.ScaleItem_s1r7otue{-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;margin-bottom:9px;}
.ScaleIconContainer_sz6uobl{position:relative;}
.ScaleHeader_s1bg0nyv{font-size:0.875rem;padding:0 4px;padding-left:6px;color:var(--s1bg0nyv-0);font-family:Inter-SemiBold,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;}
.ScaleDetail_s1lwbivx{font-size:0.875rem;color:var(--s1lwbivx-0);}.ScaleDetail_s1lwbivx span{font-family:Inter-SemiBold,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;font-weight:600;padding:0 4px;}
.ScaleSubtext_slneqir{font-size:0.875rem;color:var(--slneqir-0);}
.DirectorshipsIcon_d2dkexv{width:30px;height:30px;}.DirectorshipsIcon_d2dkexv path{fill:var(--d2dkexv-0);}
.CountriesIcon_c1ahb778{width:30px;height:30px;}.CountriesIcon_c1ahb778 path{fill:var(--c1ahb778-0);}
.SocialReachIcon_sox0xij{width:30px;height:30px;}.SocialReachIcon_sox0xij path{fill:var(--sox0xij-0);}
.DescriptionTitleContainer_d3bzoj0{display:-webkit-inline-box;display:-webkit-inline-flex;display:-ms-inline-flexbox;display:inline-flex;-webkit-align-items:flex-end;-webkit-box-align:flex-end;-ms-flex-align:flex-end;align-items:flex-end;font-size:0.875rem;}
.DescriptionTitle_d1ea91oa{color:#858996;}
.DescriptionContainer_dtvesn1{font-size:0.875rem;margin-bottom:0;overflow-y:auto;}
.QuotesIcon_q1ql4y3e{float:left;margin:5px 10px 0 0;}.QuotesIcon_q1ql4y3e path{fill:var(--q1ql4y3e-0);}
.ScaleSubtextWithInfoIcon_s1inz4ub.ScaleSubtext_slneqir{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}
.PersonalDetailsContent_p1ab1hbc{background-color:#EDEDED;padding:16px 16px 16px 16px;height:100%;}.PersonalDetailsContent_p1ab1hbc dt{margin-bottom:0;}
.CustomSectionFooter_c1i5bjh7{border-top:1px solid #ffffff;}
.BioText_bg2qh7r{margin-right:5px;}
.SocialReachTooltipList_s7ub3j9{padding-top:8px;padding-left:25px;margin:0;}
.NoSocialPresenceLabel_nqx6z2l{margin:0;padding-bottom:8px;}
.SmallSocialReachIconInfographic_s15r1wv{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}.SmallSocialReachIconInfographic_s15r1wv .SocialReachIcon_sox0xij{height:35px;width:35px;padding-bottom:0;}.SmallSocialReachIconInfographic_s15r1wv .ScaleHeader_s1bg0nyv{padding:0 5px;}
.RiskCountIcon_rmlkgnw{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;aspect-ratio:1;min-width:21px;border-radius:50%;font-size:0.625rem;background-color:#ef7273;line-height:17px;color:#ffffff;border:2px solid #EDEDED;padding:var(--rmlkgnw-0);padding-left:var(--rmlkgnw-1);position:absolute;bottom:-6px;right:-3px;}.RiskCountIcon_rmlkgnw:hover{cursor:pointer;}
.TooltipContent_tdcv58u{text-align:left;padding:8px;max-height:325px;overflow-y:auto;width:240px;}
.TooltipHeader_t1kffx60{border-bottom:1px solid #d2d3d8;padding-bottom:6px;}
.TooltipBody_trt1phq{max-height:200px;overflow-y:auto;padding-top:5px;}
.RiskyCountries_r1nu7tgx{margin-bottom:8px;line-height:1.3;font-size:0.875rem;}
.RiskAssigners_rurmktv{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;padding-top:8px;gap:8px;}
.OfficershipCard_o1oywyhb{background-color:#EDEDED;border-radius:6px;padding:16px;}.OfficershipCard_o1oywyhb:not(:last-of-type){margin-bottom:8px;}
.Name_nrpnct9{family:Inter-SemiBold,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;height:20px;line-height:20px;width:100%;}
.CompanyLocation_c15w0nmk{color:#858996;}
.RoleDate_ru1xx53{color:var(--ru1xx53-0);}
.RoleDuration_r15q19ya{font-size:0.875rem;color:#858996;}
.Officerships_ousoq7g{margin-top:6px;font-size:0.875rem;}
.CompanyDetails_c1g4gy0p{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;}
.PersonDetails_p10183u0{font-size:0.875rem;border-top:1px solid #d2d3d8;padding-top:10px;margin-top:12px;}
.CompanyDetailsLeftContent_czji9x7{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;max-width:55%;}
.DetailsContainer_dczu2kd{margin-top:-4px;width:100%;}
.Role_r8duw17{color:#858996;}
.OfficershipRole_o3cg0ud + .OfficershipRole_o3cg0ud{margin-top:4px;}
.SectionLinkButton_s1z06szp{border-bottom:1px solid #858996 !important;color:#858996 !important;font-size:0.875rem !important;line-height:13px;}
.SectionTitle_sypx0mv{font-family:Inter-SemiBold,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;font-size:0.875rem;font-weight:inherit;color:#858996;margin-bottom:4px;}
.CountryFlag_ce7okz4{width:30px;}.CountryFlag_ce7okz4 img{width:24px !important;height:18px !important;margin:0;margin-right:8px !important;border-radius:2px !important;}
.RelatedCountryHeader_r9cf6yr{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-pack:justify;-webkit-justify-content:space-between;-ms-flex-pack:justify;justify-content:space-between;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}
.List_l1rhvw7r{margin-top:11px !important;padding-top:14px !important;padding-left:0 !important;border-top:1px solid #d2d3d8;list-style:none;}
.ListItem_lyjyfi9{font-size:0.875rem;}.ListItem_lyjyfi9 + .ListItem_lyjyfi9{margin-top:12px;}
.NoneIdentified_n18oq40j{color:#b3b5bd;font-size:0.875rem;font-style:italic;}
.SectionLinkText_s1tupdol{white-space:nowrap;}
.CustomSourceList_c1mmjq5e{overflow:inherit;}
.IndirectExplainer_i13tskii{padding-top:8px;}
.termClassName_txt87f7{margin:0;min-height:22px;line-height:20px;}
.detailClassName_d16xndlo{min-height:22px;line-height:20px;}
.locationRiskTag_ln8t2yl{border-radius:11px;height:23px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;padding-left:8px;opacity:1 !important;background-color:#ef7273 !important;}.locationRiskTag_ln8t2yl > svg{margin-top:0px;margin-right:-3px;}
